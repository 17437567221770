import { InstrumentSymbol, TimeOnly, TradingSession, TradingSessionTime, } from '../../domain/value-objects';
import { InstrumentRepository } from './instrument.repository';
import { Instrument } from '../../domain/entities';
import { Urlu } from '../../app/urlu/Urlu';
import { apirc } from '~/configs/apirc';
import { each, forEach, isEmpty, map } from 'lodash';
import { debugAPI } from '../../debug/debugAPI';
import { InstrumentType } from '../../domain/enumerations';
import { Exchange } from '../../domain/enumerations/exchange';
export class InstrumentService extends Urlu {
    constructor() {
        super('https://storage.googleapis.com');
    }
    getInstrument(symbolString) {
        const symbol = InstrumentSymbol.create(symbolString);
        if (!InstrumentRepository.find(symbol))
            InstrumentRepository.add(Instrument.create(symbol, InstrumentRepository.findName(symbol), InstrumentRepository.findInstrumentType(symbol), InstrumentRepository.findExchange(symbol), InstrumentRepository.findBigPointValue(symbol)));
        return InstrumentRepository.find(symbol);
    }
    getFutures(symbol) {
        return this.getInstruments(InstrumentRepository.symbolOfStockFutures.get(symbol.value) || []);
    }
    getUnderlying(symbol) {
        const symbolString = InstrumentRepository.symbolOfUnderlying.get(symbol.value.replace(/-.*/, '')) || null;
        if (!symbolString)
            return null;
        return this.getInstrument(symbolString);
    }
    getFutureSymbols(symbol) {
        return this.getInstruments(InstrumentRepository.symbolOfStockFutures.get(symbol.value) || []);
    }
    getInstruments(symbols) {
        return map(symbols, code => this.getInstrument(code));
    }
    isTaiwanFutures(symbol) {
        // 包含台指期和股期，沒有選擇權
        // 交易所是期交所，且代碼長度小於8，（因為選擇權的代碼長度是8，所以可以用這個特性來判斷是否為台灣期貨）
        return this.getInstrument(symbol).exchange.equals(Exchange.TFE) && symbol.length < 8;
    }
    async fetch() {
        await Promise.all([
            this.fetchIndusties(),
            this.fetchTradingSessionsAndBigPointValues(),
            this.fetchCommonSymbolList(),
            this.fetchExchangesAndInstrumentTypes(),
            this.fetchFutureSymbols(),
            this.fetchTaiwanStockSymbols(),
        ]);
    }
    async fetchIndusties() {
        if (isEmpty(InstrumentRepository.symbolToIndustry.industries)) {
            InstrumentRepository.symbolToIndustry = await apirc.staticJson.fetchIndustry();
        }
    }
    async fetchTradingSessionsAndBigPointValues() {
        if (isEmpty(InstrumentRepository.symbolToSessionsMap)) {
            const json = await apirc.staticJson.fetchContractInfo();
            forEach(json, (contractInfo, symbol) => {
                InstrumentRepository.symbolToBigPointValue.set(symbol, contractInfo.big_point_value);
                InstrumentRepository.symbolToSessionsMap.set(symbol, contractInfo.trading_interval.map(session => {
                    const tradingSession = TradingSession.create(TradingSessionTime.create(1, TimeOnly.parse(session.open_time)), TradingSessionTime.create(2, TimeOnly.parse(session.close_time)));
                    return tradingSession;
                }));
            });
        }
    }
    async fetchExchangesAndInstrumentTypes() {
        if (isEmpty(InstrumentRepository.symbolToExchangeMap) ||
            isEmpty(InstrumentRepository.symbolToTypeMap)) {
            debugAPI.domain.log(`TvAPIs: 正在準備必要數據...`);
            const dataOfTvAPIs = await Promise.race([
                apirc.tvAPIs.fetchAll(),
                apirc.tvAPIsOfGCP.fetchAll(),
            ]);
            forEach(dataOfTvAPIs, info => {
                InstrumentRepository.symbolToExchangeMap.set(info.symbol, Exchange.fromName(info.exchange));
                InstrumentRepository.symbolToTypeMap.set(info.symbol, info.type.includes('_futures')
                    ? InstrumentType.Future
                    : InstrumentType.fromName(info.type));
            });
        }
    }
    async fetchTaiwanStockSymbols() {
        if (isEmpty(InstrumentRepository.symbolOfStockToNameMap)) {
            debugAPI.domain.log(`台灣股票代碼: 正在準備必要數據...`);
            const symbolOfStockToNameMapp = await apirc.staticJson.fetchSymbolOfStockToNameMapp();
            InstrumentRepository.symbolOfStockToNameMap = new Map(Object.entries(symbolOfStockToNameMapp));
        }
    }
    async fetchFutureSymbols() {
        if (isEmpty(InstrumentRepository.symbolToNameMap)) {
            debugAPI.domain.log(`期貨代碼: 正在準備必要數據...`);
            const symbolToNameMapp = await apirc.staticJson.fetchSymbolToNameMapp();
            InstrumentRepository.symbolToNameMap = new Map(Object.entries(symbolToNameMapp));
        }
    }
    async fetchCommonSymbolList() {
        if (isEmpty(InstrumentRepository.symbolOfStockFutures)) {
            debugAPI.domain.log(`股票期貨代碼: 正在準備必要數據...`);
            const commonSymbolList = await apirc.staticJson.fetchCommonSymbolList();
            InstrumentRepository.symbolOfStockFutures = new Map(Object.entries(commonSymbolList.derivativeFutures));
            const fragmentList = new Map();
            each(commonSymbolList.derivativeFutures, (derivativeSymbolStringList, underlyingSymbolString) => {
                each(derivativeSymbolStringList, derivativeSymbolString => {
                    fragmentList.set(derivativeSymbolString, underlyingSymbolString);
                });
            });
            InstrumentRepository.symbolOfUnderlying = fragmentList;
        }
    }
}
